import React from 'react';
import LoginDialog from '../logindialog';

class LoginView extends React.Component
{
    render()
    {
        return <main>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10 col-md-6 col-lg-4 mx-auto">
                        <LoginDialog onSubmit={ () => alert("To jest aplikacja DEMO.") } onRememberPass={ () => false }/>
                    </div>
                </div>
            </div>
        </main>;
    }
}

export default LoginView;
