import React from 'react';
import '../scss/landing.scss';
import ScrollEvent from 'react-onscroll';
import PageVisibility from 'react-page-visibility';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import LandingContent from './_landing_content';

import BgVidMp4 from '../assets/landing/99548-1080.mp4';
import BgVidWebm from '../assets/landing/99548-1080.webm';
import LogoImg from '../assets/logo.svg';

import Footer from './_footer';
import RegisterDialog from './landing/register';
import LoginDialog from './landing/login';
import PrivacyContent from './landing/privacy';
import ContactContent from './landing/contact';

import '../scss/landing.scss';

import { push as Menu } from 'react-burger-menu'


class Landing extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            scrollblur: true,
            view: "content",
            blur: 0,
            visible: true,
            bgStyle: {},
            isMenuOpen: false
        };
        this.secondRef = React.createRef();
    }

    onmenuclick = () => {
        this.setState({isMenuOpen: true});
    };

    onMenuStateChange = (menu) => {
        if (!menu.isOpen) {
            this.setState({isMenuOpen: false});
        }
    };

    displayDefault = () => {
        this.setState(Object.assign({}, this.configureBlur(Landing.getScrollBlurVal()), {
            scrollblur: true,
            view: 'content',
            isMenuOpen: false
        }));
    };

    static getScrollBlurVal() {
        return Math.round(Math.min(2000, window.scrollY)/200);
    }

    onscroll = () => {
        this.applyBlur(Landing.getScrollBlurVal());
    };

    onvisibility = (isVisible) => {
        this.setState({ visible: isVisible });
    };

    displayAbout = () => {
        this.displayDefault();
        window.requestAnimationFrame(() => {
            if (this.secondRef && this.secondRef.current) {
                this.secondRef.current.scrollIntoView({
                    bahavior: 'smooth',
                    block: 'start',
                    inline: "start"
                });
            }
        });
    };

    displayRegister = () => {
        window.requestAnimationFrame( () => {
            window.scrollTo({top: 0, behavior: "smooth"});
            this.setState(Object.assign({}, this.configureBlur(15), {
                scrollblur: false,
                blur: 50,
                view: 'register',
                isMenuOpen: false
            }));
        });
    };

    displayLogin = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.setState(Object.assign({}, this.configureBlur(15), {
            scrollblur: false,
            blur: 50,
            view: 'login',
            isMenuOpen: false
        }));
    };

    displayContact = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.setState(Object.assign({}, this.configureBlur(15), {
            scrollblur: false,
            blur: 50,
            view: 'contact',
            isMenuOpen: false
        }));
    };

    displayPrivacy = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.setState(Object.assign({}, this.configureBlur(15), {
            scrollblur: false,
            blur: 50,
            view: 'privacy',
            isMenuOpen: false
        }));
    };

    configureBlur(val) {
        if (this.state.blur === val) {
            return {};
        }

        let filter = "blur(" + val + "px)";
        return {
            blur: val,
            bgStyle: {
                filter: filter,
                "MozFilter": filter,
                "WebkitFilter": filter
            }
        };
    }

    applyBlur(val) {
        this.setState(this.configureBlur(val));
    }

    componentDidMount() {
        switch(this.props.match.path) {
            case "/register": this.displayRegister(); break;
            case "/login": this.displayLogin(); break;
            case "/about": this.displayAbout(); break;
            case "/contact": this.displayContact(); break;
            case "/privacy": this.displayPrivacy(); break;
            default: return;
        }
    }

    render(props)
    {
        let content = null;
        switch(this.state.view) {
            case 'register': content = <RegisterDialog onCloseHandler={ this.displayDefault }/>; break;
            case 'login': content = <LoginDialog onCloseHandler={ this.displayDefault }/>; break;
            case 'privacy': content = <PrivacyContent/>; break;
            case 'contact': content = <ContactContent/>; break;
            default: content = <LandingContent secondRef={ this.secondRef } onAboutClick={ this.displayAbout } onJoinClick={ this.displayRegister }/>; break;
        }
    return <div id="landing">
        <PageVisibility onChange={ this.onvisibility }/>
        { this.state.scrollblur ? <ScrollEvent handleScrollCallback={this.onscroll} /> : null }
        <div className="bg" style={this.state.bgStyle}>
            <div id="bgvideo">
                <figure id="videobg">
                    <video poster={null} playsInline autoPlay muted={ true } loop paused={ this.state.visible ? "false" : "true"}>
                      <source src={ BgVidMp4 } type="video/mp4"/>
                      <source src={ BgVidWebm } type="video/mp4"/>
                    </video>
                    <div id="vid-cover"/>
                </figure>
            </div>
            <div id="gradient"/>
        </div>
        <Menu
            width='300px'
            pageWrapId="main"
            outerContainer="landing"
            right
            isOpen={ this.state.isMenuOpen }
            onStateChange={ this.onMenuStateChange }
            customBurgerIcon={ false }
        >
            <div className="d-flex flex-column align-items-center" style={ {height: "100vh"}} >
                <div className="w-100 p-3 pointer" onClick={ this.onMenuStateChange }>
                    <div className="align-items-center">
                        <img src={ LogoImg } alt="Incentive Heroes"/>
                    </div>
                </div>
                <div className="w-100 menu-spacer"/>
                  <div className="menu-items w-100 p-3">
                    <button type="button" className="btn btn-primary w-100 mt-3 mb-3 p-3" onClick={ this.displayLogin }>LOGOWANIE</button>
                    <button type="button" className="btn btn-outline-primary w-100 mb-3 p-3" onClick={ this.displayRegister }>REJESTRACJA</button>
                  </div>
                <div className="w-100 menu-spacer"/>
                <div className="menu-items w-100 p-0" style={ {background: "rgba(255,255,255, .02)"}}>
                  <button type="button" className="btn btn-menu w-100" onClick={ this.displayAbout }>O PROGRAMIE</button>
                  <button type="button" className="btn btn-menu w-100" onClick={ this.displayPrivacy }>POLITYKA PRYWATNOŚCI</button>
                  <button type="button" className="btn btn-menu w-100" onClick={ this.displayContact }>KONTAKT</button>
                </div>
            </div>

        </Menu>
        <div className="content" id="main">
            <header className={ this.state.blur>0 ? ' color' : null }>
                <div className="container-fluid p-1">
                    <nav className="nav navbar-expand-sm">
                        <div className="pointer navbar-brand" onClick={ this.displayDefault }>
                            <div className="d-flex align-items-center">
                                <img src={ LogoImg } alt="Incentive Heroes"/>
                                <div className="spacer mx-3 d-none d-lg-block"/>
                                <div className="slogan d-none d-lg-block">
                                    <div><em>Motivation Engagement Loyalty</em></div>
                                    <div>CRM & MARKETING PLATFORM</div>
                                </div>
                            </div>
                        </div>
                        <div className="ml-auto navbar-nav flex-row align-items-center justify-content-end">
                            <button type="button" className="btn btn-outline-primary d-none d-sm-block" onClick={ this.displayRegister }>REJESTRACJA</button>
                            <button type="button" className="ml-3 btn btn-primary d-sm-flex align-items-center d-none d-sm-block" onClick={ this.displayLogin }>
                                <span className="d-none d-lg-inline mr-2">LOGOWANIE</span>
                                <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-180.000000, -2159.000000)" fill="#FFFFFF">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <button type="button" className="ml-3 btn btn-primary d-flex align-items-center d-sm-none" onClick={ this.onmenuclick }>
                                <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-180.000000, -2159.000000)" fill="#FFFFFF">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <button type="button" className="ml-3 btn btn-light" onClick={ this.onmenuclick }>
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1.25rem" height="1.25rem" viewBox="0 0 124 124" xmlSpace="preserve">
                                    <g>
                                        <path d="M112,6H12C5.4,6,0,11.4,0,18s5.4,12,12,12h100c6.6,0,12-5.4,12-12S118.6,6,112,6z"/>
                                        <path d="M112,50H12C5.4,50,0,55.4,0,62c0,6.6,5.4,12,12,12h100c6.6,0,12-5.4,12-12C124,55.4,118.6,50,112,50z"/>
                                        <path d="M112,94H12c-6.6,0-12,5.4-12,12s5.4,12,12,12h100c6.6,0,12-5.4,12-12S118.6,94,112,94z"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
            { content }
            { this.state.view === 'content' ? ( <ReactCSSTransitionGroup
                transitionName="footer"
                transitionEnterTimeout={150}
                transitionLeaveTimeout={200}
            >
                { this.state.blur === 0 ? <Footer key={"ftr"} sticky={true} jump={true} awards={true} jumpHandle={ this.displayAbout }/> : null }
            </ReactCSSTransitionGroup>
                ) : null }
            <Footer sticky={false} jump={false} awards={false}/>
        </div>
    </div>;
    };
}

export default Landing;
