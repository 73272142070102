import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import RegisterStep1 from "./register_step1";
import RegisterStep2 from "./register_step2";

class LandingRegisterDialog extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            step: 'Step1',
            step1data: {}
        };
    };

    onSubmit = (values, formikBag) => {
        if (this.state.step === "Step1") {
            this.setState({step1data: values, step: "Step2"});
            formikBag.setSubmitting(false);
        } else {
            formikBag.setSubmitting(true);
            formikBag.setStatus("Trwa oczekiwanie...");
            let _t = this;
            setTimeout(function() {
                alert("FORMULARZ REJESTRACJI TO TYLKO DEMO");
                _t.props.onCloseHandler();
            }, 2000);
        }
    };

    onCorrectDataBtn = () => {
        this.setState({
            step: "Step1"
        });
    };

    render()
    {
        return <main>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 mx-auto">
                        <ReactCSSTransitionGroup
                            transitionName="dialog_register"
                            transitionAppear={ true }
                            transitionEnter={ false }
                            transitionLeave={ false }
                            transitionAppearTimeout={ 200 }
                        >
                            {
                                this.state.step === 'Step1' ?
                                <RegisterStep1 onSubmit={ this.onSubmit } onCloseHandler={ this.props.onCloseHandler }/>
                                :
                                <RegisterStep2 onSubmit={ this.onSubmit } onCloseHandler={ this.props.onCloseHandler } onBackHandler={ this.onCorrectDataBtn } data={ this.state.step1data }/>
                            }
                        </ReactCSSTransitionGroup>
                    </div>
                </div>
            </div>
        </main>;
    }
}

export default LandingRegisterDialog;