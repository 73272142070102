import { setLocale } from 'yup';

const YupLocale = {
    mixed: {
        required: 'Pole jest wymagane.'
    },
    string: {
        email: 'Pole musi zawierać prawidłowy adres e-mail',
        min: `Minimum \${min} znaków`
    }
};

export default {
    setLocale,
    init: function() {
        this.setLocale(YupLocale)
    }
};

