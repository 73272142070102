import React from 'react';
import PropTypes from 'prop-types';
import DefaultAvatarImg from '../assets/useravatar_bg.svg';
import Form from 'react-bootstrap/Form';
import '../scss/_logindialog.scss';
import posed from "react-pose";

const AvatarDiv = posed.div({
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: "spring" }
    },
    hidden: {
        opacity: 0.01,
        scale: .5
    }
});

const Button = posed.button({
    pressable: true,
    init: {
        scale: 1
    },
    press: {
        scale: .8
    }
});

class LoginDialog extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = Object.assign({}, {
            isRemembered: props.username && props.username !== "",
            avatarLoaded: false,
            username: props.username,
            password: "",
            avatar: props.avatar,
            isRememberMeChecked: props.isRememberMeChecked
        });
    }

    onRememberMeChange = (e) => {
        this.setState({ isRememberMeChecked: e.target.checked });
    };

    onUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    };

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value });
    };

    onChangeAccountClick = () => {
        this.setState({
            isRemembered: false,
            username: null,
            avatar: null,
            avatarLoaded: false
        });
    };

    onAvatarLoaded = () => {
        this.setState( { avatarLoaded: true });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit({
            username: this.state.username,
            password: this.state.password,
            isRememberMeChecked: this.state.isRememberMeChecked
        });

    };

    render()
    {
        const { props, state } = this;
        const rememberedUser = state.isRemembered;
        const error = props.error && props.error !== "";

        return <Form className="login-dialog" onSubmit={ this.handleSubmit }>
            <div className="card">
            <div className="avatar text-center">
                <div className="avatar-placeholder">
                    <AvatarDiv pose={ state.avatarLoaded ? "visible" : "hidden" } className="rounded-circle">
                        <img src={ state.avatar || DefaultAvatarImg} alt="Avatar" className="img-fluid rounded-circle" onLoad={ this.onAvatarLoaded }/>
                    </AvatarDiv>
                </div>
                {
                    rememberedUser
                        ? <div>
                                <h1>{ props.fullName }</h1>
                                <button type="button" className="btn btn-link" onClick={ this.onChangeAccountClick } disabled={ props.isBusy }>Zmień konto</button>
                          </div>
                        : <h1>Logowanie</h1>
                }

            </div>
                { error ?
                    <div className="p-4 is-invalid error d-flex">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" width="1rem" height="1rem">
                                <path fill="#fff" fillRule="evenodd" d="M11.55 5v7c0 .56-.47 1-1.05 1-.58 0-1.05-.44-1.05-1V5c0-.55.47-1 1.05-1 .58 0 1.05.45 1.05 1zm0 10c0 .56-.47 1-1.05 1-.58 0-1.05-.44-1.05-1 0-.55.47-1 1.05-1 .58 0 1.05.45 1.05 1zm7.35 2c0 .55-.47 1-1.05 1H3.15c-.58 0-1.05-.45-1.05-1V3c0-.55.47-1 1.05-1h14.7c.58 0 1.05.45 1.05 1v14zm0-17H2.1C.94 0 0 .9 0 2v16c0 1.11.94 2 2.1 2h16.8c1.16 0 2.1-.9 2.1-2V2c0-1.1-1.05-2-2.1-2z"/>
                            </svg>
                        </div>

                        <div>
                            { props.error }
                        </div>
                    </div>
                    :
                    null
                }
            <div className="card-body">
                { !rememberedUser ?
                    <Form.Group>
                        <Form.Label>
                            <svg width="1rem" height="1rem" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                                    <g transform="translate(-180.000000, -2159.000000)">
                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                            <path d="M134,2008.99998 C131.783496,2008.99998 129.980955,2007.20598 129.980955,2004.99998 C129.980955,2002.79398 131.783496,2000.99998 134,2000.99998 C136.216504,2000.99998 138.019045,2002.79398 138.019045,2004.99998 C138.019045,2007.20598 136.216504,2008.99998 134,2008.99998 M137.775893,2009.67298 C139.370449,2008.39598 140.299854,2006.33098 139.958235,2004.06998 C139.561354,2001.44698 137.368965,1999.34798 134.722423,1999.04198 C131.070116,1998.61898 127.971432,2001.44898 127.971432,2004.99998 C127.971432,2006.88998 128.851603,2008.57398 130.224107,2009.67298 C126.852128,2010.93398 124.390463,2013.89498 124.004634,2017.89098 C123.948368,2018.48198 124.411563,2018.99998 125.008391,2018.99998 C125.519814,2018.99998 125.955881,2018.61598 126.001095,2018.10898 C126.404004,2013.64598 129.837274,2010.99998 134,2010.99998 C138.162726,2010.99998 141.595996,2013.64598 141.998905,2018.10898 C142.044119,2018.61598 142.480186,2018.99998 142.991609,2018.99998 C143.588437,2018.99998 144.051632,2018.48198 143.995366,2017.89098 C143.609537,2013.89498 141.147872,2010.93398 137.775893,2009.67298"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            E-mail
                        </Form.Label>
                        <Form.Control isInvalid={ error }
                                      type="email"
                                      required
                                      className="text-center"
                                      name="username"
                                      autoComplete="username"
                                      disabled={ props.isBusy }
                                      value={ state.username }
                                      onChange={ this.onUsernameChange }
                        />
                    </Form.Group>
                    : null
                }

                <Form.Group>
                    <Form.Label>
                        <svg width="1rem" height="1rem" viewBox="0 0 14 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" strokeWidth="1" fillRule="evenodd">
                                <g transform="translate(-303.000000, -5079.000000)">
                                    <g transform="translate(56.000000, 160.000000)">
                                        <path d="M254,4931.04165 C254.552,4931.04165 255,4931.49024 255,4932.04296 C255,4932.59567 254.552,4933.04426 254,4933.04426 C253.448,4933.04426 253,4932.59567 253,4932.04296 C253,4931.49024 253.448,4931.04165 254,4931.04165 L254,4931.04165 Z M254,4935.04686 C255.654,4935.04686 257,4933.69911 257,4932.04296 C257,4930.3868 255.654,4929.03905 254,4929.03905 C252.346,4929.03905 251,4930.3868 251,4932.04296 C251,4933.69911 252.346,4935.04686 254,4935.04686 L254,4935.04686 Z M249,4936.9974 L259,4936.9974 L259,4926.98438 L249,4926.98438 L249,4936.9974 Z M252,4924.98178 L252,4923.03124 C252,4921.9268 252.897,4921.0026 254,4921.0026 C255.103,4921.0026 256,4921.97787 256,4922.97917 L258,4922.97917 C258,4920.97657 256.209,4919 254,4919 C251.791,4919 250,4920.81937 250,4923.03124 L250,4924.98178 L247,4924.98178 L247,4939 L261,4939 L261,4924.98178 L252,4924.98178 Z"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        Hasło
                    </Form.Label>
                    <Form.Control
                        isInvalid={ error }
                        type="password"
                        className="text-center"
                        required
                        name="password"
                        autoComplete="password"
                        disabled={ props.isBusy }
                        value={ state.password }
                        onChange={ this.onPasswordChange }
                    />
                </Form.Group>
            </div>
            <div className="card-body d-flex justify-content-between">
                <Form.Check custom>
                    <Form.Check.Input name="rememberme" checked={ state.isRememberMeChecked } id="login-rememberme" onChange={ this.onRememberMeChange } disabled={ props.isBusy }/>
                    <Form.Check.Label htmlFor="login-rememberme">Pamiętaj mnie</Form.Check.Label>
                </Form.Check>

                <button type="button" onClick={ props.onRememberPass } className="btn btn-link text-primary p-0 m-0" disabled={ props.isBusy }>Przypomnij hasło</button>
            </div>

            <div className="card-body d-flex justify-content-center">
                <Button type="submit" className="btn btn-primary" disabled={ props.isBusy }>{ props.isBusy ? <div className="spinner-border spinner-border-sm mr-2"/> : null }Zaloguj</Button>
            </div>
                </div>
        </Form>;
    }
}

LoginDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onRememberPass: PropTypes.func.isRequired,
    avatar: PropTypes.string,
    username: PropTypes.string,
    fullName: PropTypes.string,
    isBusy: PropTypes.bool,
    isRememberMeChecked: PropTypes.bool,
    error: PropTypes.string
};

LoginDialog.defaultProps = {
    isBusy: false,
    error: null,
    isRememberMeChecked: false,
    username: "",
    fullName: null
};

export default LoginDialog;