import WeDifferImg from "../assets/landing/color_logo_tinny.svg";
import Img2 from "../assets/landing/img2.jpg";
import Img3 from "../assets/landing/img6.jpg";
import React from "react";
import posed from 'react-pose';

const childprops = {
    enter: {
        opacity: 1,
        x: 0
    },
    exit: {
        opacity: 0,
        x: 100
    }
};

const H1 = posed.h1(childprops);
const H2 = posed.h2(childprops);
const H3 = posed.h3(childprops);
const P = posed.p(childprops);
const PosedImg = posed.img(childprops);
const PosedBtn = posed.button(Object.assign({}, childprops, {
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 }
}));

const PosedDiv = posed.div({
    enter: {
        staggerChildren: 50
    }
});

class LandingContent extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            mounted: false
        };
    }

    componentDidMount() {
        this.setState({mounted: true});
    }

    render() {
        const props = this.props;

        return <main className="landing-content">
            <PosedDiv pose={ this.state.mounted ? 'enter':'exit' } className="container">
                <div className="row fullscreen align-items-center">
                    <div className="col-12 col-md-4 d-flex align-items-center justify-content-center mb-5">
                        <PosedImg src={WeDifferImg} alt="We differ only in colors" className="img-fluid rounded-circle"/>
                    </div>
                    <div className="col-12 col-md-8 col-lg-7 offset-lg-1">
                        <div className="cta">
                            <H2 className="mb-0 pb-2">Projekt Incentive</H2>
                            <H1>Tajlandia <em>2019</em></H1>
                            <H3>Program Partnerski Twojej firmy.</H3>
                            <P className="mt-4 mb-5">
                                Testuj nowe idee. Rozpal wyobraźnie. Rozbudź ambicje. Wyznaczaj i pokonuj granice.
                                Rzeczywiste, osobiste i biznesowe. Zdobywaj nagrody.
                            </P>
                            <PosedBtn className="btn btn-outline-light mr-3" onClick={props.onAboutClick}>O PROGRAMIE</PosedBtn>
                            <PosedBtn className="btn btn-primary" onClick={props.onJoinClick}>DOŁĄCZ TERAZ</PosedBtn>
                        </div>
                    </div>
                </div>
                <div style={{height: "3rem"}} ref={props.secondRef}/>
                <section className="row">
                    <div className="col-12 col-md-4 d-flex align-content-top justify-content-center mb-5">
                        <div>
                            <div className="border rounded-circle position-sticky">
                                <img src={Img2} alt="We differ only in colors"
                                     className="img-fluid rounded-circle border"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-7 offset-lg-1">
                        <h1>Program Partnerski</h1>
                        <p className="display-5 py-3">Projekt <em>Tajlandia 2019</em></p>

                        <h2>Zarejestruj się w programie i otwórz na nowe wyzwania.</h2>
                        <h4 className="mb-5">Wypełnij formularz. Zapoznaj się z regulaminem. Twoje konto zostanie przez
                            nas niezwłocznie aktywowane.</h4>

                        <h3>Bierz udział w konkursach.</h3>
                        <p className="mb-5">Zyskaj motywację i inspirację do działania!</p>

                        <h3>Wygrywaj nagrody.</h3>
                        <p className="mb-5">Drukarki, projektory i inne niespodzianki czekają na Ciebie.</p>

                        <h4>Zostań jednym z dwudziestu Zwycięzców i jedź po przygodę swojego życia, podczas której:</h4>
                        <ul className="mb-5">
                            <li>zbudujesz trwałe i satysfakcjonujące relacje</li>
                            <li>zdobędziesz nowe doświadczenia</li>
                            <li>wyznaczysz i pokonasz swoje granice</li>
                            <li>przezwyciężysz swoje słabości</li>
                            <li>zasmakujesz niesamowitych potraw</li>
                        </ul>

                        <h2>Bo pełnię swoich możliwości osiągamy przy ludziach, którzy są naszym tłem i dla których
                            potrafimy być tłem równie atrakcyjnym!</h2>

                        <div className="cta">
                            <button className="btn btn-primary mt-4" onClick={props.onJoinClick}>DOŁĄCZ DO NAS!</button>
                        </div>
                    </div>
                </section>

                <section className="row">
                    <div className="col-12 col-md-4 d-flex align-content-top justify-content-center mb-5 mt-5">
                        <div>
                            <div className="border rounded-circle position-sticky">
                                <img src={Img3} alt="We differ only in colors"
                                     className="img-fluid rounded-circle border"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-7 offset-lg-1 mb-5">
                        <div className="d-none d-md-block section-spacer"/>
                        <h1 className="mb-5">Nagroda glówna</h1>
                        <h3>7 dniowa przygoda życia w Tajlandii</h3>
                        <ul className="mb-5">
                            <li>popłyniesz statkiem w wieczorny rejs po menamie - dorzeczu Bangkoku</li>
                            <li>zobaczysz najsłynniejsze zabytki Bangkoku, m.in. Wat Arun (Świątynie Brzasku), Wat Pho
                                (Świątynie Leżącego Buddy), Wat Phra (Świątynie Szmaragdowego Buddy), Wielki Pałac
                                Królewski
                            </li>
                            <li>spędzisz niezapomnianą noc, odwiedzając podniebne drink bary, usytuowane na najwyższych
                                wieżowcach Bangkoku
                            </li>
                            <li>udasz się na rowerową, kulinarną podróż, podczas której nabędziesz umiejętność
                                samodzielnego przygotowywania i komponowania dań, w towarzystwie najznamienitszych
                                szefów kuchni
                            </li>
                            <li>sprawdzisz czy Twoja osobowość jest większa niż Twoje Ego, pomagając miejscowym
                                wolontariuszom przy osieroconych słoniach
                            </li>
                            <li>spróbujesz swoich sił w tajskim boksie</li>
                            <li>.. a na zakończenie, odpoczniesz na jednej z rajskich plaż.</li>
                        </ul>
                        <div className="cta">
                            <button className="btn btn-outline-primary mt-4" onClick={props.onJoinClick}>ZAREJESTRUJ
                                SIĘ!
                            </button>
                        </div>
                    </div>
                </section>
            </PosedDiv>
        </main>;
    }
}

export default LandingContent;
